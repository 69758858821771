import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import 'rxjs/add/operator/filter';
import { Location } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FacebookService, InitParams } from 'ngx-facebook';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


    constructor(private translateService: TranslateService,
        public location: Location, private facebookService: FacebookService) { }

    ngOnInit() {
        this.initFacebookService();
    }
    // facebook init
    private initFacebookService(): void {
        const initParams: InitParams = { xfbml: true, version: 'v3.2' };
        this.facebookService.init(initParams);
    }
}
