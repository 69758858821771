import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    private toggleButton: any;
    private sidebarVisible: boolean;
    private currentLang: string;

    constructor(public location: Location, private element: ElementRef,
        private translateService: TranslateService, private router: Router) {
        this.sidebarVisible = false;

        this.currentLang = this.translateService.getBrowserLang();
        if (this.currentLang !== 'en' && this.currentLang !== 'hu') {
            this.currentLang = 'en'
        }

        this.translateService.use(this.currentLang);
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    switchLanguage() {
        if (this.currentLang === 'en') {
            this.currentLang = 'hu';
        } else if (this.currentLang === 'hu') {
            this.currentLang = 'en';
        }

        this.translateService.use(this.currentLang);
    }

    getFlag() {
        if (this.currentLang === 'en') {
            return 'flag-icon flag-icon-gb';
        } else if (this.currentLang === 'hu') {
            return 'flag-icon flag-icon-hu';
        }
    }

    getLanguageName() {
        if (this.currentLang === 'en') {
            return 'English';
        } else if (this.currentLang === 'hu') {
            return 'Magyar';
        }
    }

    scroll(id: string) {
        document.querySelector(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

}
