import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { FacebookModule } from 'ngx-facebook';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LandingComponent } from './landing/landing.component';

import { AlertModule } from 'ngx-alerts';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { IvyCarouselModule } from 'angular-responsive-carousel';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    'blur': 5,
    'delay': 0,
    'fastFadeOut': true,
    'fgsColor': '#000000',
    'fgsPosition': 'center-center',
    'fgsSize': 60,
    'fgsType': 'ball-spin-clockwise',
    'gap': 24,
    'masterLoaderId': 'master',
    'overlayBorderRadius': '0',
    'overlayColor': 'rgba(40, 40, 40, 0.8)',
    'pbColor': '#000000',
    'pbDirection': 'ltr',
    'pbThickness': 3,
    'hasProgressBar': true,
    'textColor': '#FFFFFF',
    'textPosition': 'center-center',
    'maxTime': -1,
    'minTime': 300
};

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        LandingComponent,
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        AlertModule.forRoot({ maxMessages: 5, timeout: 5000, position: 'right' }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        RecaptchaModule,
        RecaptchaFormsModule,
        ReactiveFormsModule,
        FacebookModule.forRoot(),
        IvyCarouselModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
