import { Component } from '@angular/core';
import { AlertService } from 'ngx-alerts';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {



  constructor(
    private alertService: AlertService,
    private translateService: TranslateService) {
  }

  openDrive() {  
    window.open("https://drive.google.com/drive/folders/1-SmmNYkWyOohFgv5B9wasva2NcqopbSY?usp=sharing","_blank");
  }

  downloadChecklist() {
    saveAs('./assets/files/yourinnerboomer-checklist.pdf', 'yourinnerboomer-checklist.pdf');
  }

  showAppleDialog() {
    this.alertService.warning(this.translateService.instant('notification.apple-app-soon'));
  }


}
